import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { PAYPAL_THEME } from '@paypalcorp/pp-react-theme';

import * as ComponentsMap from '@paypalcorp/brc-components';

import PropTypes from 'prop-types';

import { NavFragment } from '@paypalcorp/globalnavsdk/NavFragment';

export const renderComponent = (
  { componentData, pageInfo, index },
  processComponentProps,
) => {
  let { componentType, ...componentProps } = componentData;
  const Component = ComponentsMap[componentType];
  const wasComponentFound = !!Component;

  if (!wasComponentFound) {
    return <div data-missed={componentType} />;
  }

  if (typeof processComponentProps === 'function') {
    componentProps = processComponentProps({
      componentProps,
      componentType,
      pageInfo,
    });
  }

  return <Component {...componentProps} key={`${componentType}-${index}`} />;
};

export const renderCmsComponents = (
  components,
  pageInfo,
  processComponentProps,
) =>
  components.map((componentData, index) =>
    renderComponent({ componentData, pageInfo, index }, processComponentProps),
  );

const { SubNav, Parent, ArticleStickyButton, Disclosure } = ComponentsMap;

const renderSubNav = ({ subNav }) => {
  if (!subNav) {
    return <div data-missed="SubNav" />;
  }

  // Checking if the SubNav has at least one Dropdown item
  const hasDropdownItems = subNav?.itemCollection?.some(
    (item) => item?.type === 'Dropdown',
  );

  return (
    <SubNav className={!hasDropdownItems && 'no-dropdown-items'} {...subNav} />
  );
};

const PARDOT_SAVE_FORM_ENDPOINT = 'brc/api/pardot/save-form-data';

const getParentProviderProps = ({
  fpti,
  pageInfo = {},
  isBrowserEnv = false,
}) => {
  return {
    pageSegment: pageInfo.pageSegment,
    fpti: isBrowserEnv ? fpti : {},
    isCMSPreview: pageInfo.isCMSPreview,
    theme: pageInfo.isCompactTheme ? 'compact' : 'regular',
    runMode: pageInfo.runMode,
    csrfToken: pageInfo.csrf,
    pageURI: pageInfo.pageURI,
    clientInfo: pageInfo.clientInfo,
    loggedIn: pageInfo.isLoggedIn,
    country: pageInfo.country,
    appEntryPoint: pageInfo.resourceHub === 'BRC' ? 'brc/': 'money-hub/',
    apis: {
      pardot: {
        saveFormData: `/${pageInfo?.country?.toLowerCase()}/${PARDOT_SAVE_FORM_ENDPOINT}`,
      }
    }
  };
};

const PageTemplate = ({
  articleStickyButton,
  children,
  className,
  fpti = {},
  globalDisclosure,
  pageInfo,
  subNav,
}) => {
  const isBrowserEnv = typeof window !== 'undefined';

  const parentProviderProps = getParentProviderProps({
    fpti,
    pageInfo,
    isBrowserEnv,
  });

  return (
    <ThemeProvider theme={PAYPAL_THEME}>
      <Parent {...parentProviderProps}>
        <NavFragment type="header" />
        <div
          id="document-body-root"
          className={className}
          data-served-by={pageInfo.contentServedFrom}
          data-brc-experience="rebrand-experience"
        >
          {renderSubNav({ subNav })}
          {children}
          {articleStickyButton && (
            <ArticleStickyButton {...articleStickyButton} />
          )}
        </div>
        <NavFragment type="footer" />
        {globalDisclosure && (
          <section className="footer-disclosure grid">
            <div className="disclosure-container">
              <Disclosure {...globalDisclosure} />
            </div>
          </section>
        )}
      </Parent>
    </ThemeProvider>
  );
};

export const pageCommonPropTypes = {
  content: PropTypes.shape({
    pageData: PropTypes.shape({
      articleStickyButton: PropTypes.shape({
        ariaLabel: PropTypes.string.isRequired,
      }),
      components: PropTypes.arrayOf(
        PropTypes.shape({
          componentType: PropTypes.string.isRequired,
        }),
      ).isRequired,
      subNav: PropTypes.object.isRequired,
      globalDisclosure: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  pageInfo: PropTypes.object.isRequired,
  fpti: PropTypes.object,
};

PageTemplate.propTypes = {
  articleStickyButton: PropTypes.shape({
    ariaLabel: PropTypes.string.isRequired,
  }),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fpti: PropTypes.object,
  globalDisclosure: PropTypes.object,
  pageInfo: PropTypes.object.isRequired,
  subNav: PropTypes.object.isRequired,
};

export default PageTemplate;
